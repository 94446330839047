import * as React from 'react';
import {
  ProgressSteps,
  NumberedStep,
} from 'baseui/progress-steps';
import { StatefulTabs, Tab, ORIENTATION } from 'baseui/tabs';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider, styled } from 'baseui';
import { useStyletron } from 'baseui';

import {
  Button,
  SHAPE,
  ButtonProps,
  KIND,
  SIZE,
} from 'baseui/button';
const engine = new Styletron();



const SpacedButton=(props) =>{
  return (
    <Button
      {...props}
      shape={SHAPE.pill}
      kind={KIND.secondary}
      size={SIZE.compact}
      overrides={{
        BaseButton: {
          style: ({$theme}) => ({
            marginLeft: $theme.sizing.scale200,
            marginRight: $theme.sizing.scale200,
            marginTop: $theme.sizing.scale800,
          }),
        },
      }}
    />
  );
}

const ProgressStepsContainer=()=> {
  const [current, setCurrent] = React.useState(0);
  const [css] = useStyletron();

  return (
    <StyletronProvider value={engine} >
    <BaseProvider theme={LightTheme} >

    <ProgressSteps
      current={current}
      orientation={ORIENTATION.horizontal}
    >
      <NumberedStep title="Create Account">
        <SpacedButton disabled>Previous</SpacedButton>
        <SpacedButton onClick={() => setCurrent(1)}>
          Next
        </SpacedButton>
      </NumberedStep>
      <NumberedStep title="Verify Payment">
        <SpacedButton onClick={() => setCurrent(0)}>
          Previous
        </SpacedButton>
        <SpacedButton onClick={() => setCurrent(2)}>
          Next
        </SpacedButton>
      </NumberedStep>
      <NumberedStep title="Add Payment Method">
        <SpacedButton onClick={() => setCurrent(1)}>
          Previous
        </SpacedButton>
        <SpacedButton disabled>Next</SpacedButton>
      </NumberedStep>
    </ProgressSteps>

    
    </BaseProvider >

    </StyletronProvider >
  );
}

export default ProgressStepsContainer;