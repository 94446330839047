import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import firebase from "firebase";

const Banner = ({ banner, uid }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoplaySpeed, setAutoplaySpeed] = useState(3000);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const divRef = useRef(null);

  const handleScroll = (event) => {
    const div = divRef.current;
    if (div.scrollLeft < div.scrollWidth - div.clientWidth && event.deltaX > 0) {
      alert('Scrolled right');
    }
    setIsAutoPlay(false);
  };

  useEffect(() => {
    const handleResize = () => {
      // Force re-render on window resize
      setCurrentSlide((prev) => prev);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: isAutoPlay,
    autoplaySpeed: autoplaySpeed,
    afterChange: setCurrentSlide,
    beforeChange: (oldIndex, newIndex) => {
      if (oldIndex > newIndex) {
        setAutoplaySpeed(5000000);
      }
    },
    style: {
      height: '27vh',
      width: window.innerWidth < 768 ? '90vw' : '25vw',
      marginBottom: 15,
    },
  };

  const mediaStyle = {
    height: '27vh',
    objectFit: 'cover',
    border: '3px solid #000',
    borderRadius: 18,
    width: window.innerWidth < 768 ? '90vw' : '25vw',
  };

  const dotStyle = {
    width: '6.5px',
    height: '6.5px',
    borderRadius: '50%',
    backgroundColor: '#EEE',
    margin: 5,
  };

  const activeDotStyle = {
    ...dotStyle,
    backgroundColor: '#000',
  };

  const updateClick = async (slide, index) => {
    if (window.location.hostname !== "localhost") {
      try {
        const url = slide?.url.startsWith("http") ? slide?.url : `https://${slide?.url}`;
        window.open(url, '_blank');

        const db = firebase.firestore();
        const featuresDocRef = db.collection("users").doc(uid).collection("banner").doc("images");
        const docSnapshot = await featuresDocRef.get();

        if (docSnapshot.exists) {
          const featuresData = docSnapshot.data();
          const updatedData = featuresData?.data?.map((item, idx) =>
            idx === index ? {
              ...item,
              last_clicked_at: item.last_clicked_at ? [...item.last_clicked_at, Date.now()] : [Date.now()],
            } : item
          );

          await featuresDocRef.update({ data: updatedData });
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error opening website or updating data:", error);
      }
    }
  };

  const isVideoURL = (url) => {
    const videoExtensions = ['.mp4', '.mov', '.avi', '.webm', '.mkv', '.flv', '.m4v', '.3gp', '.3g2'];
    return videoExtensions.includes(url.substring(url.lastIndexOf('.')).toLowerCase());
  };

  return (
    <div ref={divRef} onScroll={handleScroll}>
      {banner?.length === 1 ?
        banner.map((slide, index) => (
          <div
            key={index}
            onClick={() => updateClick(slide, index)}
            style={{ ...mediaStyle, border: 'none', width: mediaStyle.width }}
          >
            {isVideoURL(slide?.image) ?
              <video
                className="video__player"
                loop
                src={slide?.image}
                playsInline
                autoPlay
                controls={false}
                muted
                style={mediaStyle}
              />
              :
              <img
                src={slide?.image}
                alt="Ad"
                style={mediaStyle}
              />
            }
          </div>
        ))
        :
        <Slider {...settings}>
          {banner?.map((slide, index) => (
            <div
              key={index}
              onClick={() => updateClick(slide, index)}
              style={mediaStyle}
            >
              {isVideoURL(slide?.image) ?
                <video
                  className="video__player"
                  loop
                  src={slide?.image}
                  playsInline
                  autoPlay
                  controls={false}
                  muted
                  style={mediaStyle}
                />
                :
                <img
                  src={slide?.image}
                  alt="Ad"
                  style={mediaStyle}
                />
              }
            </div>
          ))}
        </Slider>
      }

      {banner?.length > 1 &&
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: -5 }}>
          {banner.map((_, index) => (
            <div key={index} style={index === currentSlide ? activeDotStyle : dotStyle}></div>
          ))}
        </div>
      }
    </div>
  );
};

export default Banner;
