import React, { useEffect, useRef } from 'react';
import ScrollSnap from 'scroll-snap';

const ScrollSnapComponent = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      // Initialize scroll-snap
      const snap = new ScrollSnap(container, {
        snapDestinationY: 'nearest', // Snap to the nearest item
        timeout: 1000 // Example timeout
      });
      snap.bind();

      // Clean up the scroll-snap instance when component unmounts
      return () => {
        snap.unbind();
      };
    }
  }, []);

  return (
    <div ref={containerRef} style={styles.container}>
      {/* Scrollable items with snap */}
      <div style={styles.item}>1</div>

      <div style={styles.item}>2</div>
      <div style={styles.item}>3</div>
      <div style={styles.item}>4</div>
      <div style={styles.item}>5</div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    height: '100vh', // Set container height to 100vh
    overflowY: 'scroll',
    scrollSnapType: 'y mandatory', // Enable vertical scrolling with snap
  },
  item: {
    scrollSnapAlign: 'start', // Snap items to the start of the container
    height: '100vh', // Set item height to 100vh
    width: '100%',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
  },
};

export default ScrollSnapComponent;
