import React, { useEffect, useRef, useState } from "react";
import "./Video.css";
import { Spinner } from "baseui/spinner";

function Video({ url, sound, videos, index }) {
  const [playing, setPlaying] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);
  const [isVideoInView, setIsVideoInView] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [loadTime, setLoadTime] = useState(null);

  useEffect(() => {
    const timeoutPagination = setTimeout(() => {
      setShowPagination(true);
    }, 3000);

    return () => {
      clearTimeout(timeoutPagination);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(onVideoIntersection, {
      threshold: 0.5,
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVideoInView) {
      videoRef.current.play().then(() => {
        setIsLoading(false);
      });
    } else {
      videoRef.current.pause();
    }
  }, [isVideoInView]);

  const onVideoIntersection = (entries) => {
    entries.forEach((entry) => {
      setIsVideoInView(entry.isIntersecting);
    });
  };

  const renderPaginationDots = () => {
    const dotStyle = {
      width: "6.5px",
      height: "6.5px",
      borderRadius: "50%",
      backgroundColor: "white",
      margin: 5,
    };

    const activeDotStyle = {
      ...dotStyle,
      backgroundColor: "black",
      opacity: 0.51,
    };

    return videos.map((video, idx) => (
      <div key={idx} style={idx === 0 ? activeDotStyle : dotStyle}></div>
    ));
  };

  const handleLoadedMetadata = (event) => {
    const { duration, videoWidth, videoHeight } = event.target;
    setIsLoading(false);
  };

  const handleLoadStart = () => {
    setLoadTime(performance.now());
  };

  const handleLoadedData = () => {
    const loadDuration = (performance.now() - loadTime) / 1000;
    console.log(`Video ${index} took ${loadDuration.toFixed(2)} seconds to load.`);
    setIsLoading(false);
  };

  return (
    <div className="video-container">
      <video
        id={url}
        key={url}
        className="video__player"
        loop
        ref={videoRef}
        src={url}
        playsInline
        preload="metadata"  // Preload metadata for quicker loading
        controls={false}
        muted={sound}
        onLoadStart={handleLoadStart}
        onLoadedData={handleLoadedData}
        onLoadedMetadata={handleLoadedMetadata}
      ></video>

      {index === 0 && showPagination && (
        <div id="pagination" className="pagination-container">
          <div className="pagination-dots">
            {renderPaginationDots()}
          </div>
        </div>
      )}

      {isLoading && (
        <div className="spinner-container">
          <Spinner $color="#000" />
        </div>
      )}
    </div>
  );
}

export default Video;
