import React, { useState } from 'react';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import firebase from "firebase"

function Form(props) {
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: ''
    });

    const [selectedOption, setSelectedOption] = useState('male');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('18-30 yrs');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        console.log(`User entered ${name}: ${value}`);
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        console.log(`User selected ${option}`);
    };

    const handleAgeGroupChange = (option) => {
        setSelectedAgeGroup(option);
        console.log(`User selected age group: ${option}`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();


    
        // Check if all fields are filled
        if (!formData.name || !formData.phoneNumber || !selectedOption || !selectedAgeGroup) {
            alert('Please fill in all fields');
            return;
        }
    
        // All fields are filled, proceed with submission
        console.log('Form submitted:', {
            name: formData.name,
            phoneNumber: formData.phoneNumber,
            gender: selectedOption,
            ageGroup: selectedAgeGroup
        });

        function generateRandomToken(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
          }
          
        
          const tokenLength = 16;
          const randomToken = generateRandomToken(tokenLength);
          console.log('Random Token:', randomToken);
        
        localStorage.setItem("device_id", randomToken);
        // Retrieve the identifier from local storage
        const deviceIdForStorage = localStorage.getItem("device_id");
        console.log("Device ID Storage:", deviceIdForStorage);
        

        firebase.firestore().collection("users").doc("k8kPP10kj6aDg1ChQnETzH1oArf1").collection("form").doc("submit_data")
        .set({ "data": firebase.firestore.FieldValue.arrayUnion(
            {
                name: formData.name,
                phonenumber: formData.phoneNumber,
                gender: selectedOption,
                age_group: selectedAgeGroup,
                device_id:localStorage.getItem("device_id")
            }
        ) })

    };
    

  






    return (
        <div style={{ textAlign: 'left', fontFamily: 'Arial, sans-serif', padding: '20px' }}>
            <h2 style={{ fontSize: '8vw', marginTop: '10px' }}>Good Morning</h2>


            <form onSubmit={handleSubmit} style={{ width: '100%', marginBottom: '50px', marginTop: '50px',display:"flex",flexDirection:"column",justifyContent:"space-around",alignItems:"center" }}>
                <FormControl label="Name" caption="">
                    <Input
                        autoFocus
                        type="name"
                        id="name"
                        value={formData?.name}
                        onChange={(e)=>  setFormData({
                            ...formData,
                            ["name"]: e.target.value
                        })}
                        placeholder="John Doe"
                    />
                </FormControl>

                <FormControl label="Phone Number" caption="">
                    <Input
                        type="phonenumber"
                        id="phoneNumber"
                        placeholder="9876543210"
                        value={formData?.phoneNumber}
                        onChange={(e)=>  setFormData({
                            ...formData,
                            ["phoneNumber"]: e.target.value
                        })}
                    />
                </FormControl>

                <FormControl label="Gender" caption="">

                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5px' }}>
    <div
        onClick={() => handleOptionChange('male')}
        style={{
            marginRight: 24,
            padding: "10px",
            fontSize: 14,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: "12px",
            width: "35%",
            border: selectedOption === 'male' ? '2px solid black' : '2px solid #EEE',
            color: 'black',
            textAlign: 'center',
            fontFamily:"UberMoveMedium"
        }}>
        Male
    </div>
    <div
        onClick={() => handleOptionChange('female')}
        style={{
            marginRight: 24,
            padding: "10px",
            fontSize: 14,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: "12px",
            width: "35%",
            border: selectedOption === 'female' ? '2px solid black' : '2px solid #EEE',
            color: 'black',
            textAlign: 'center',
            fontFamily:"UberMoveMedium"
        }}>
        Female
    </div>
</div>
</FormControl>


<FormControl label="Age group" caption="" >
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px' }}>
                        <div
                            onClick={() => handleAgeGroupChange('18-30 yrs')}
                            style={{
                                marginRight: 24,
                                padding: "10px",
                                fontSize: 14,
                                paddingLeft: 15,
                                paddingRight: 15,
                                borderRadius: "12px",
                                border: selectedAgeGroup === '18-30 yrs' ? '2px solid black' : '2px solid #EEE',
                                color: 'black',
                                textAlign: 'center',
                                width: "35%",
                                fontFamily: "UberMoveMedium"
                            }}>
                            18-30 yrs
                        </div>
                        <div
                            onClick={() => handleAgeGroupChange('30-45 yrs')}
                            style={{
                                marginRight: 24,
                                padding: "10px",
                                fontSize: 14,
                                paddingLeft: 15,
                                paddingRight: 15,
                                borderRadius: "12px",
                                border: selectedAgeGroup === '30-45 yrs' ? '2px solid black' : '2px solid #EEE',
                                color: 'black',
                                textAlign: 'center',
                                width: "35%",
                                fontFamily: "UberMoveMedium"
                            }}>
                            30-45 yrs
                        </div>
                        <div
                            onClick={() => handleAgeGroupChange('45+ yrs')}
                            style={{
                                padding: "10px",
                                fontSize: 14,
                                paddingLeft: 15,
                                paddingRight: 15,
                                borderRadius: "12px",
                                border: selectedAgeGroup === '45+ yrs' ? '2px solid black' : '2px solid #EEE',
                                color: 'black',
                                textAlign: 'center',
                                width: "35%",
                                fontFamily: "UberMoveMedium"
                            }}>
                            45+ yrs
                        </div>
                    </div>
                </FormControl>

                <Button
                    style={{
                        width: '90%', padding: '15px', border: '2px solid black', borderRadius: '5px', backgroundColor: '#FFF',position:"absolute",bottom:"2%",
                        fontSize: '5.5vw', backgroundColor: 'black', color: 'white', cursor: 'pointer', fontFamily: "UberMoveRegular", marginTop: '20px'
                    }}
                    onClick={handleSubmit}>Submit</Button>


            </form>


            <div style={{position:"absolute", top:20,right:10}} >
  <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none">
    <title>Close</title>
    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="3" />
  </svg>
</div>


        </div>
    );
}

export default Form;
