import React, { useEffect, createRef, useRef } from 'react';
import { Button } from "baseui/button";

import './index.css';
import {
    ThemeProvider,
    createTheme,
    lightThemePrimitives
  } from "baseui";
import {
    PhoneInput,
    COUNTRIES,
  
    StyledFlag,
  } from 'baseui/phone-input';

import { BsFillHandbagFill ,BsCreditCard2BackFill,BsHourglassSplit,BsCreditCard2FrontFill} from "react-icons/bs";
import { RiCellphoneFill,RiWhatsappFill,RiMapPin2Fill,RiShareForwardFill ,RiMailFill,RiPhoneFill,RiShoppingCartFill,RiMicrosoftFill,RiFileList3Fill} from "react-icons/ri";
import { Input } from "baseui/input";
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider, styled} from 'baseui';
import {Redirect, BrowserRouter as Router, Route, IndexRoute} from "react-router-dom";

import {StatefulInput} from 'baseui/input';
import {Heading, HeadingLevel} from 'baseui/heading';
import {DisplayLarge, HeadingLarge, HeadingMedium, HeadingSmall, HeadingXSmall, Paragraph3} from 'baseui/typography';
import {Drawer, SIZE} from 'baseui/drawer';
import {StatefulTabs, StyledTab} from 'baseui/tabs';
import {Label2} from 'baseui/typography';
import { FaStoreAlt,FaMapMarkerAlt,FaClock ,FaArrowRight ,FaLongArrowAltRight,FaHandsHelping,FaCheck, FaArrowLeft} from "react-icons/fa";
import { IoStorefrontSharp,IoDocumentTextSharp ,IoMdCheckmark} from "react-icons/io";
import { Accordion, Panel } from "baseui/accordion";
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {PaymentCard, valid} from 'baseui/payment-card';
import {MaskedInput} from 'baseui/input';
import { HiArrowLeft, HiArrowRight} from "react-icons/hi";
import {
  SnackbarProvider,
  useSnackbar,
  DURATION,
  SnackbarElement
} from 'baseui/snackbar';
import {
  Display1,
  Display2,
  Display3,
  Display4,
} from 'baseui/typography';
import {
  Card,
  StyledBody,
  StyledAction
} from "baseui/card";
import {Grid, Cell} from 'baseui/layout-grid';
import ArrowRight from 'baseui/icon/arrow-right'
import {  MdWavingHand,MdQrCode2 } from 'react-icons/md';
import { FileUploader } from "baseui/file-uploader";
import firebase from "firebase"
import {RadioGroup, Radio} from 'baseui/radio';
import { FormControl } from "baseui/form-control";
import { ButtonGroup } from "baseui/button-group";
import {Textarea} from 'baseui/textarea';
import {Block} from 'baseui/block';
import {useStyletron} from 'baseui';
import { PinCode } from "baseui/pin-code";

import {Alert} from 'baseui/icon';
import Check from 'baseui/icon/check'
import { Table } from "baseui/table";

import {
    Checkbox,
    LABEL_PLACEMENT
  } from "baseui/checkbox";
import Delete from 'baseui/icon/delete'
import { Avatar } from "baseui/avatar";
import {FixedMarker} from 'baseui/map-marker';
import DeleteAlt from 'baseui/icon/delete-alt';
//import { Tabs, Tab } from "baseui/tabs";
import loaderAnimation from "./loader.json"
import {Toast} from 'baseui/toast';

import {
    Tabs,
    Tab,
    StyledTabList,
    StyledTabPanel,
  } from 'baseui/tabs-motion';
  import Skeleton from 'react-loading-skeleton'
  import SkeletonTheme from 'react-loading-skeleton'
  import { StyledSpinnerNext, Spinner } from "baseui/spinner";
  import moment from "moment"
  import 'react-loading-skeleton/dist/skeleton.css'
import { ContactSupportTwoTone } from '@material-ui/icons';
import {Notification, KIND} from 'baseui/notification';
import { GoCheck } from 'react-icons/go';
import { Select } from '@material-ui/core';


const engine = new Styletron();

function Negative() {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.sizing.scale500,
        color: theme.colors.negative400,
      })}
    >
      <Alert size="18px" />
    </div>
  );
}

function CustomFlag(props) {
    const {children, ...rest} = props;
    return <StyledFlag iso={props.$iso} {...rest} />;
  }

function getFormOverrides(width) {
  return {
    ControlContainer: {
      style: {
        width,
        marginRight: '5px',
      },
    },
  };
}

function useInterval(callback, delay) {
  const savedCallback = React.useRef(() => {});
  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
// useFakeProgress is an elaborate way to show a fake file transfer for illustrative purposes. You
// don't need this is your application. Use metadata from your upload destination if it's available,
// or don't provide progress.
function useFakeProgress() {
  const [fakeProgress, setFakeProgress] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  function stopFakeProgress() {
    setIsActive(false);
    setFakeProgress(0);
  }
  function startFakeProgress() {
    setIsActive(true);
  }
  useInterval(
    () => {
      if (fakeProgress >= 100) {
        stopFakeProgress();
      } else {
        setFakeProgress(fakeProgress + 10);
      }
    },
    isActive ? 500 : null,
  );
  return [fakeProgress, startFakeProgress, stopFakeProgress];
}




export default function LoginScreen(props) {

  var hours = new Date().getHours();
 
  const [greeting, setGreeting] = React.useState( hours > 4 && hours <12 ?"Good Morning" : hours > 12 && hours <16 ? "Good Afternoon" : hours > 16 && hours <22 ?"Good Evening" : hours > 16 && hours <23 ?"Good Night" : hours > 0 && hours <4 ?"Good Night" :"Welcome"  );
  const [description, setDescription] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [price, setPrice] = React.useState();
  const [isValidPrice, setIsValidPrice] = React.useState(false);
  const [isVisitedPrice, setIsVisitedPrice] = React.useState(false);
  const showPriceError = !isValidPrice && isVisitedPrice && price 
  const [businessUid, setBusinessUid] = React.useState(props.b_uid);
  const [fileData, setFileData] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(0);
  const [imageSize, setImageSize] = React.useState(0);
  const [number, setNumber] = React.useState('');
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [code, setCode] = React.useState();
  let counterScrolled = 0;
  let animationContainer =React.useRef();
  const [imageLoadedHighlight, setImageLoadedHighlight]=React.useState(false);
  const [imageLoadedProductSection, setImageLoadedProductSection]=React.useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [isOpenDrawerPayments, setIsOpenDrawerPayments] = React.useState(false);
  const [isOpenDrawerMaps, setIsOpenDrawerMaps] = React.useState(false);

  //const [activeKey, setActiveKey] = React.useState("0");
  const [activeKey, setActiveKey] = React.useState(0);
  const [css] = useStyletron();
  const [business, setBusiness] = React.useState({});
  const [products, setProducts] = React.useState([]);
  const [showLottie, setShowLottie] = React.useState(true);
  const [phonenumber, setPhonenumber] = React.useState("");
  const [website, setWebsite] = React.useState('');
  const [whatsapp, setWhatsapp] = React.useState('');
  const [country, setCountry] = React.useState(COUNTRIES.IN);
  const [selectedAction, setSelectedAction] = React.useState("");
  const [isActive, setIsActive] = React.useState("call");
  const [showNoti, setShowNoti] = React.useState(false);
  const [showSubmit, setShowSubmit] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showWrongOTPAlert, setShowWrongOTPAlert] = React.useState(false);

  const [gotohome, setgotohome] = React.useState(false);
  const [gotopage, setgotopage] = React.useState("");
  const [otpSent, setOtpSent] = React.useState(false);
  const [values, setValues] = React.useState('');
  const {enqueue} = useSnackbar();
  const [final, setfinal] = React.useState('');
  const QRCode = require('qrcode')
  const buttonRef = React.useRef(null);
  const [
    progressAmount,
    startFakeProgress,
    stopFakeProgress,
  ] = useFakeProgress();


  const [user, setUser] = React.useState(null);

  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      console.log("already logged in user -> ", user.uid);
      setUser(user);
      const userRef = firebase.firestore().collection('users').doc(user.uid);
      const doc = await userRef.get();
      if (doc.exists) {
        const planType = doc.data()?.plan_type;
        console.log("plan_type -> ", planType);

        if (planType === "Free Plan" || planType === "Image Plan" || planType === "Video Plan") {

          // User exists in Firestore and has a valid plan, redirect to dashboard
          if (window.location.pathname !== `/dashboard${user.uid}` &&
              !window.location.pathname.includes("action") &&
              !window.location.pathname.includes("qrcode") &&
              !window.location.pathname.includes("test")) {
            window.location.replace(`/dashboard${user.uid}`);
          }

        } else console.log("User has an invalid plan type");
      } 
    } 

  });





 
  const navigationWhatsapp= () =>{
    window.open('whatsapp://send?text=Join stage-stream&phone=14155238886' , '_self')
  }




  const onImgLoad=({target:img})=>{
    setImageSize(img.offsetHeight)
    setImageLoadedHighlight(true) 
}

  const handleScroll = (e) => {
     
      setScrolled(1)
     
 }

const turnOffLottie=()=>{
  setShowLottie(false)
}

const myStyle={
      height:'100vh',
      fontSize:'50px',
      width: "100%",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: "center",
        overflow: "scroll",
        overflowX:"hidden",
        backgroundColor:"#fff",
        objectFit: "cover"
    };

useEffect(() => {
  const img = new Image()
  img.src = business.image
  img.onload = () => {
    
    setBackgroundImage('url(' +business.image + ')') 
  }
}, [business.image])


const hideNoti=()=>{
  setShowNoti(false)
  
}

const showNotiBanner=()=>{
    setShowNoti(true)
}

{console.log(" GO TO PAGE ===> ", gotopage )}


const onSignInSubmit = () => {
  if (phonenumber.length !== 10) {
    return alert("Please enter a valid mobile number");
  }

  setUploading(true);


  const number = country.dialCode + phonenumber;
  const verify = new firebase.auth.RecaptchaVerifier("recaptcha-container", { size: "invisible" });

  firebase.firestore().collection('users').where("phonenumber", "==", number).get()
    .then(querySnapshot => {
      if (!querySnapshot.empty) {
        setgotopage("home");
      }

      return firebase.auth().signInWithPhoneNumber(number, verify);
    })
    .then((result) => {
      setfinal(result);
      setTimeout(showNotiBanner, 0);
      setOtpSent(true);
      setTimeout(hideNoti, 5000);
      setUploading(false);

    })
    .catch((err) => {
      alert(err.message || "An error occurred");
      window.location.reload();
    });
};

const ValidateOtp = () => {
  if (values.length !== 6 || final === null) {
    return alert("Enter a valid OTP");
  }

  setUploading(true);

  const formattedOtp = values.replace(/\s/g, "");
  const OSName = getOSName();
  const confirmation = final.confirm(formattedOtp);

  confirmation.then((result) => {
    setShowLoader(true);

    console.log("result?.user?.uid ==> ", result?.user?.uid)

    if (gotopage === "" && result?.user?.uid != undefined ) {
      generateQRCode(result?.user?.uid, OSName);
    }

    else{

      const number = country.dialCode + phonenumber;

      firebase.firestore().collection('users').where("phonenumber", "==", number).get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {


          if (doc?.data()?.phoneAuth == false) {
            firebase.firestore().collection("users").doc(doc?.data()?.uid).update({ phoneAuth: true })
              .then(() => {
                window.open("/dashboard" + doc?.data()?.uid, "_self");
              })
              .catch(error => {
                console.error("Error updating Firestore:", error);
              });
          }          

        });
      })
      .catch((error) => {
        window.open("https://1qr.co.in/login" , "_self")
      });

      
    } 


  })
  .catch((err) => {
    console.log("Error verifying OTP:", err);

    if (err.message && err.message.includes("invalid")) {
      setValues(["", "", "", "", "", ""]);
      return alert("Enter a correct OTP");
    }



  });
};

const generateQRCode = async (uid, OSName) => {
  console.log(" generateQRCode ==> ", uid, country.dialCode + phonenumber);

  QRCode.toDataURL(`https://1qr.co.in/qrcode${uid}`, async (err, code) => {
    if (err) {
      return alert("BAD REQUEST. Please try again later.");
    }

    try {
      await firebase.firestore().collection("users").doc(uid).set({
        "qrURL": code,
        "os": OSName,
        "uid": uid,
        "account_created_on": Date.now(),
        "phonenumber": country.dialCode + phonenumber,
        "total_scans": 0,
        "plan_type": "Free Plan",
        "active_feature": ["call"],
        "phoneAuth": true,

      });

      // Initialize subcollections
      const batch = firebase.firestore().batch();
      const statsRef = firebase.firestore().collection("users").doc(uid).collection("stats").doc("visitors_data");
      const bannerImagesRef = firebase.firestore().collection("users").doc(uid).collection("banner").doc("images");
      const bannerVideosRef = firebase.firestore().collection("users").doc(uid).collection("banner").doc("videos");
      const featuresCallRef = firebase.firestore().collection("users").doc(uid).collection("features").doc("call");

      batch.set(statsRef, { "last_visit_at": [] });
      batch.set(bannerImagesRef, { "data": [] });
      batch.set(bannerVideosRef, { "data": [] });
      batch.set(featuresCallRef, { "data": { phonenumber: country.dialCode + phonenumber } });

      await batch.commit();

      window.open("/dashboard" + uid, "_self");
      
    } catch (err) {
      alert("An error occurred while setting up user data. Please try again.");
      console.error("Error setting up user data:", err);
    }
  });
};


const getOSName = () => {
  if (navigator.userAgent.indexOf("Win") !== -1) return "Windows";
  if (navigator.userAgent.indexOf("Mac") !== -1) return "Macintosh";
  if (navigator.userAgent.indexOf("Linux") !== -1) return "Linux";
  if (navigator.userAgent.indexOf("Android") !== -1) return "Android";
  if (navigator.userAgent.indexOf("like Mac") !== -1) return "iOS";
  return "Unknown OS";
};


  const setoptcode= (c)=>{

    

    setValues(c)
    showSubmit.push(parseInt(c))
  
  }


  const textStyle = {
    fontFamily: 'UberMoveMedium, Arial, sans-serif',
    fontSize: '24px'
  };


      return (
 

        <StyletronProvider value={engine}>
  <BaseProvider theme={LightTheme}>

    <div style={{ width: "100%", height: "10%", backgroundColor: "black", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center", padding: 10,marginBottom:"10%" }}>
      <div style={{fontFamily: "UberMoveMedium", fontSize: '24px', margin:15}}>1QR</div>
      <div  style={{fontFamily: "UberMoveMedium", fontSize: '24px', margin:15}}>Get Started</div>
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
   
      backgroundColor: '#fff',
      fontFamily: 'UberMoveMedium',
      color: '#000'
    }}>
   <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '50vh',
      backgroundColor: '#fff',
      fontFamily: "UberMoveMedium",
    }}>
      <div style={{  display: !otpSent ? "block" : "none",
        width: '100%',
        maxWidth: '400px',
        backgroundColor: '#fff',
        padding: '24px',
        textAlign: 'center',
        boxSizing: 'border-box',
       
      }}>
        <h2 style={{ marginBottom: '24px', fontFamily: 'UberMoveMedium', fontSize:24 }}>What's your phone number?</h2>



<PhoneInput
  text={phonenumber}
  country={country}
  onTextChange={(event) => { setPhonenumber(event.currentTarget.value); }}
  onCountryChange={(event) => { setCountry(event.option); }}
  autoFocus
  overrides={{
    FlagContainer: {
      component: CustomFlag,
      style: {
        // Inline styles for the flag container
   
        width: '20px', // Set the width as needed
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      
      },
    },
    DialCode: {
      style: {
        // Inline styles for the dial code part of the input
        marginLeft: '8px',
        color: '#333',
        fontSize: '18px',
      },
    },
    Input: {
      style: {
        // Inline styles for the input field
        height: '40px',
        paddingLeft: '12px',
        paddingRight: '12px',
        border: '1px solid #ced4da',
        borderRadius: '4px',
        fontSize: '24px',
        width: '100%',
      },
    },
  }}
/>

<div id="recaptcha-container"></div>

        <button 
         onClick={() => onSignInSubmit()}
        style={{
           fontFamily: 'UberMoveRegular',
           width: '100%',
            padding: '12px 15px',
            margin: '0 0 16px 0',
            fontSize: '16px',
            borderRadius: '8px',
          width: '100%',
          padding: '15px 15px',
          margin: '0 0 24px 0',
          fontSize: '16px',
 
          border: 'none',
          backgroundColor: '#000',
          color: '#fff',
          boxSizing: 'border-box',
          marginTop:20
        }}>
          {uploading ? 
          <div style={{ display: "flex", fontFamily: "UberMoveLight", alignContent: "center",justifyContent:"center" }}>
                  <div style={{marginRight:20,alignItems:"center",marginTop:6}}>Sending OTP</div>
                  <Spinner color="#FFF" size={10}></Spinner></div> : <div>Continue</div>
                  }
   
        </button>

        <hr style={{ border: 'none', borderTop: '1px solid #d6d6d6', margin: '0 0 14px 0', }} />
        <button 
          onClick={()=> window.open(`tel:${916363751774}`, "_self") }        
          style={{
            fontFamily: 'UberMoveRegular',
            width: '100%',
              padding: '12px 15px',
              margin: '0 0 16px 0',
              fontSize: '16px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#4285F4',
              color: '#fff',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
          {/* Google Icon */}
          Contact Support
        </button>
        {/* Repeat for Apple and Facebook buttons */}

        <p style={{ fontSize: '12px', color: '#666', fontFamily: 'UberMoveRegular' ,maxWidth: '350px',}}>
          By proceeding, you consent to get SMS for OTP verification, including by automated means, from 1QR.
        </p>
  
      </div>



      <div style={{  display: otpSent ? "block" : "none",
        width: '100%',
        maxWidth: '400px',
        backgroundColor: '#fff',
        padding: '24px',
        boxSizing: 'border-box',
      
      }}>
        <h2 style={{ marginBottom: '24px', fontFamily: 'UberMoveMedium', fontSize:24,lineHeight:1.2 }}>Enter the 6-digit code sent to you at {country.dialCode + phonenumber}</h2>


        <Input
                                         autoComplete
                                          autoFocus
                                       size={"large"}
                                        inputMode="tel"
                                        onChange={e => setoptcode(e.target.value)}
                                        placeholder="123456"
                                         
                                        overrides={{
                                          Root: {
                                            style: ({ $theme }) => ({
                                              outline: `#000 1.75px solid`,
                                              backgroundColor: "#fff"
                                            })
                                          },
                                          InputContainer: {
                                            style: ({ $theme }) => ({
                                              backgroundColor: "#fff",
                                              fontSize:200
                                            })
                                          }
                                        }}
                                      />


        <button 
        onClick={ValidateOtp}
        style={{
           fontFamily: 'UberMoveRegular',
           width: '100%',
            padding: '12px 15px',
            margin: '0 0 16px 0',
            fontSize: '16px',
            borderRadius: '8px',
          width: '100%',
          padding: '15px 15px',
          margin: '0 0 24px 0',
          fontSize: '16px',
 
          border: 'none',
          backgroundColor: '#000',
          color: '#fff',
          boxSizing: 'border-box',
          marginTop:20
        }}>
          {uploading ? <div style={{ display: "flex", fontFamily: "UberMoveLight", alignContent: "center",justifyContent:"center" }}>
                  <div style={{marginRight:20,alignItems:"center",marginTop:6}}>Please Wait</div>
                  <Spinner color="#FFF" size={10}></Spinner></div> : <div>Confirm</div>
                  }
   
        </button>

        <hr style={{ border: 'none', borderTop: '1px solid #d6d6d6', margin: '0 0 14px 0', }} />
        <button 
          onClick={()=> window.open(`tel:${916363751774}`, "_self") }        

        style={{
         fontFamily: 'UberMoveRegular',
         width: '100%',
          padding: '12px 15px',
          margin: '0 0 16px 0',
          fontSize: '16px',
          borderRadius: '8px',
          border: 'none',
          backgroundColor: '#4285F4',
          color: '#fff',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {/* Google Icon */}
          Contact Support
        </button>
        {/* Repeat for Apple and Facebook buttons */}

        <p style={{ fontSize: '12px', color: '#666', fontFamily: 'UberMoveRegular' ,maxWidth: '350px',textAlign:"center",marginTop:50}}>
          By proceeding, you agree to our terms & conditions.
        </p>

  
      </div>



    </div>

    </div>



        {showNoti ? <div style={{ position: "fixed", bottom: 25, right: '5%' }}>
          <Notification kind={KIND.positive} closeable overrides={{ Body: { style: ({ $theme }) => ({ backgroundColor: "#048848", }) }, InnerContainer: { style: ({ $theme }) => ({ color: "white", fontSize: '18px' }) }, CloseIcon: { style: ({ $theme }) => ({ color: "white" }) } }}>
            {() => `OTP Sent Successfully`}
          </Notification>
        </div> : null}

        {phonenumber.length > 10 ? <div style={{ position: "fixed", top: 225, right: '5%' }}>
          <Notification kind={KIND.negative} closeable overrides={{ Body: { style: ({ $theme }) => ({ backgroundColor: "red" }) }, InnerContainer: { style: ({ $theme }) => ({ color: "white", fontSize: '18px' }) }, CloseIcon: { style: ({ $theme }) => ({ color: "white" }) } }}>
            {() => `10 digit limit.`}
          </Notification>
        </div> : null}

        {values.length > 6 ? <div style={{ position: "fixed", top: 225, right: '5%' }}>
          <Notification kind={KIND.negative} closeable overrides={{ Body: { style: ({ $theme }) => ({ backgroundColor: "red" }) }, InnerContainer: { style: ({ $theme }) => ({ color: "white", fontSize: '18px' }) }, CloseIcon: { style: ({ $theme }) => ({ color: "white" }) } }}>
            {() => `6 digits only.`}
          </Notification>
        </div> : null}

        {showWrongOTPAlert ? <div style={{ position: "fixed", top: 150, right: '5%' }}>
          <Notification kind={KIND.negative} closeable overrides={{ Body: { style: ({ $theme }) => ({ backgroundColor: "red" }) }, InnerContainer: { style: ({ $theme }) => ({ color: "white", fontSize: '18px' }) }, CloseIcon: { style: ({ $theme }) => ({ color: "white" }) } }}>
            {() => `Enter OTP carefully.`}
          </Notification>
        </div> : null}

      <p style={{ fontSize: '16px', color: '#666', fontFamily: 'UberMoveBold' ,position:"absolute", bottom:10,textAlign:"center",justifyContent:"center",display:"flex",flexDirection:"row",width:"100%"}}>
        Made in 🇮🇳
        </p>

  </BaseProvider>
</StyletronProvider>



      );
  

  }



              
