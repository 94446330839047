

import React, { useState, useEffect } from 'react';


const slides = [

  {
     imageURL :"https://i.guim.co.uk/img/media/c6efa5f7e8df78a30d584efd0fe0c912b33e9399/0_106_3469_2081/master/3469.jpg?width=700&quality=85&auto=format&fit=max&s=dbbe4dbf46672d835c3b5c10e05e9205",
     title  : "Get a Ride with Uber",
     description : "Convenient and reliable rides.",
     link : "https://uber.com"
  },
  {
    imageURL :"https://i0.wp.com/files.tripstodiscover.com/files/2022/03/Navarro-Guest-House.jpg?resize=784%2C521",
    title  : "Stay at a AirBnB",
    description : "Convenient and reliable stays",
    link : "https://airbnb.com"
  },

  {
    imageURL :"https://public.com/wp-content/uploads/2021/08/Stripe-IPO-1024x512.png",
    title  : "Money APIs",
    description : "Convenient and safe payments",
    link : "https://stripe.com"
  },

]



const UberAd = () => {


  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000); // Interval of 5 seconds

    return () => clearInterval(interval);
  }, [slides.length]);






  const imageStyle = {
    
    height: '100.05%',
    width:"40%",
    objectFit: 'cover',
    borderTopLeftRadius: '16px', // Adjust as needed
    borderBottomLeftRadius: '16px', // Adjust as needed
  };



  const adStyles = {
    container: {
      border: '3px solid #000',
      borderRadius: '18px',
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '400px',
      margin: '0 auto',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    image: {
      width: '100px',
      height: '100px',
      borderRadius: '10px',
    },
    textContainer: {
      display:"flex",
      flexDirection: 'column',
      alignItems: "flex-end",
      justifyContent: 'space-between',
      paddingRight:10,
      paddingTop:"5%",
      paddingBottom:"5%"

    },
    title: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      marginBottom: '10',
      color:"#000"
      
    },
    description: {
      fontSize: '0.75rem',
      color: '#555',
      marginBottom: '10px',
      alignItems:"left"
    },
  };

  return (

    <>
    {slides.map((slide, index) => (
    <a
        key={index}
        href={slide.link}
        target="_blank" // Open link in a new tab
        rel="noopener noreferrer" // Recommended for security reasons
        style={{
              border: '3px solid #000',
              borderRadius: '20px',
              display: 'flex',
              flexDirection:"roww",
              justifyContent:"space-between",
              maxWidth: '400px',
              margin: '0 auto',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textDecoration: 'none',
              height:'20vh',
              width:"90vw",
              display: index === currentSlide ? 'flex' : 'none',
        }}>
      <img
        src={slide.imageURL}
        alt="Ad"
        style={imageStyle}
      />
      <div style={adStyles.textContainer}>
        <div style={adStyles.title}>{slide.title}</div>
        <div style={adStyles.description}>{slide.description}</div>

         
        <button
          style={{
            background: '#000',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            padding: '10px 15px',
            cursor: 'pointer',
            fontSize: '14px',
          }}
        >
          Learn More
        </button>
        
        <div >Ad</div>
      </div>
      
    </a>
    ))
        }

        </>
  );
};

export default UberAd;
