// App.js

import React, { useState } from 'react';
import './App.css';

function App() {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  const generateQRCode = () => {
    if (fullName !== '' && phoneNumber !== '' && email !== '') {
      setShowQRCode(true);
    } else {
      alert('Fill all fields correctly');
    }
  };

  return (
    <div className="App">
      <section>
        <h1>ECOMMERCE WEBSITE CODE</h1>
        {!showQRCode && (
          <div className="form">
            <div className="flex m20">
              <label>Full Name*</label>
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                className="m10 name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="flex m20">
              <label>Whatsapp Number(With ISD)*</label>
              <input
                type="text"
                name="phone"
                placeholder="Ex- +919064973840"
                className="m10 number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="flex m20">
              <label>Email*</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="m10 email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex m20">
              <button className="generate_qr" onClick={generateQRCode}>NEXT</button>
            </div>
          </div>
        )}
        {showQRCode && (
          <div className="qr_code m20">
            <p>Scan the QR Code with any UPI App and pay the amount then download the source code.</p>
            <center><img src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=upi://pay?pa=onkarjha2003@sbi%26am=1500%26tn=${phoneNumber}`} alt="QR CODE" className="get_qr m10" /></center>
            <center><img src="1.png" alt="Sample Image" className="im m10" /></center>
            <div className="flex m20">
              <label>UTR/REFERENCE/TRANSACTION ID**</label>
              <input
                type="number"
                name="transactionId"
                placeholder="UTR/REFERENCE/TRANSACTION ID**"
                className="m10 id"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>
            <button className="download_now m20" onClick={() => console.log('Download clicked')}>Download Now</button>
          </div>
        )}
      </section>
    </div>
  );
}

export default App;
