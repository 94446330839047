import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import OLDVERSIONActionScreen from './OLDVERSIONActionScreen';
import keys from "./config"
import Stats from './Stats';
import Googleads from './GoogleAds';
import LoginScreen from './LoginScreen';
import Dashboard from './Dashboard';
import firebase from "firebase";
import QRCodePage from './QRCodePage';
import { StyledSpinnerNext, Spinner } from "baseui/spinner";
import { useStyletron } from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider, styled } from 'baseui';
import ReactGA from "react-ga"
import Admin from './Admin';
import Form from "./Form"
import TestingScreen from "./TestingScreen"
import UPIAPI from './UPIAPI';
import App from './reels/App';
import ShadcnSRCDashboard from './shadcnSRC/ShadcnSRCDashboard';
import AuthScreen from './shadcnSRC/AuthScreen';
const engine = new Styletron();
const TRACKING_ID = "G-BTKGXDLVFX"
ReactGA.initialize(TRACKING_ID)

const AppRouter = () => {

  const [user, setUser] = useState(null);
  const [checking, setChecking] = useState(false);




  const checkAuth = async () => {

    console.log("login clicked");
    setChecking(true)
    firebase.auth().onAuthStateChanged(async user => {

      if (user) {
        // User is logged in
        console.log("auth ====> ", user.uid);
        setUser(user);

        // Check if the user exists in Firestore
        const userRef = firebase.firestore().collection('users').doc(user.uid);
        const doc = await userRef.get();
        let plan_type = null;

        if (doc.exists) {

          firebase.firestore().collection('users').doc(user.uid).onSnapshot(doc => {

            plan_type = doc?.data()?.plan_type;
            console.log("doc?.data()?.plan_type ===> ", plan_type);

            if (plan_type == "Free Plan" || plan_type == "Image Plan" || plan_type == "Video Plan") {
              console.log(" entering --->  ", doc?.data());
              // User exists in Firestore, redirect to dashboard
              if (window.location.pathname !== "/dashboard" + user.uid) {
                window.location.replace("/dashboard" + user.uid);
              }


            }


          })
        }


        else {
          // User does not exist in Firestore
          console.log("User does not exist in Firestore");
          // You can handle this case according to your application's logic
        }


      }
      else window.open("/login", "_self")
    })

  }



  return (
    <StyletronProvider value={engine} >
      <BaseProvider theme={LightTheme} >
{  window.location.pathname.includes("action") || window.location.pathname.includes("qrcode") || window.location.pathname.includes("test")
          ? <App userid={window.location.pathname.split("action")[1] || window.location.pathname.split("qrcode")[1] || window.location.pathname.split("test")[1]} type={window.location.pathname.includes("test") ? "test" : null} />
          :window.location.pathname.includes("testing")
    ? <TestingScreen/>
    :window.location.pathname.includes("admin")
    ? <Admin/>
    :window.location.pathname.includes("stats")
      ? <Stats uid={window.location.pathname.split("stats")[1]}/>
      : window.location.pathname.includes("reels") 
      ? <AuthScreen/>
      :window.location.pathname.includes("upi") 
      ? <UPIAPI/>
      : 
      window.location.pathname.includes("action") || window.location.pathname.includes("qrcode") || window.location.pathname.includes("test")
          ? <QRCodePage userid={window.location.pathname.split("action")[1] || window.location.pathname.split("qrcode")[1] || window.location.pathname.split("test")[1]} type={window.location.pathname.includes("test") ? "test" : null} />
          : window.location.pathname.includes("login")
            ? <LoginScreen />
            : window.location.pathname.includes("dashboard")
              ? <Dashboard uid={window.location.pathname.split("dashboard")[1]} />
              : <>
                <iframe
                  src={`https://comprehensive-things-553293.framer.app/`}
                  style={{
                    width: '100vw', /* Make iframe fill its container horizontally */
                    height: '100vh', /* Make iframe fill its container vertically */
                    border: "none" /* Remove iframe border */
                  }}
                />
                {/* {checking
                  ? <div style={{
                    width: Math.floor(window.innerWidth), borderRadius: 0,
                    backgroundColor: '#000', color: '#fff', position: "absolute", bottom: 0, right: 0, border: "30px solid #000",
                    textAlign: "center", fontFamily: "UberMoveBold", fontSize: 18, flexDirection: "row",
                    display: "flex", fontFamily: "UberMoveLight", alignContent: "center", justifyContent: "center", width: '100%', borderRadius: '8px', backgroundColor: 'black', color: 'white', padding: 10, border: 'none', cursor: 'pointer', fontFamily: "UberMoveRegular", fontSize: 18
                  }}>
                    <div style={{ marginRight: 20, alignItems: "center", marginTop: 20, marginBottom: 15, color: "white", fontSize: 18, fontFamily: "UberMoveBold" }}>Please wait </div>
                    <div style={{ marginRight: 20, alignItems: "center", marginTop: 10, marginBottom: 15, color: "white", fontSize: 18, fontFamily: "UberMoveBold" }}><Spinner color="#FFF" size={20}></Spinner></div>
                  </div>
                  
                  : <button onClick={() => checkAuth()} style={{
                    width: Math.floor(window.innerWidth), borderRadius: 0,
                    backgroundColor: '#000', color: '#fff', position: "absolute", bottom: 0, right: 0, border: "10px solid #000",
                    padding: 10, textAlign: "center", fontFamily: "UberMoveBold", fontSize: 18
                  }}>
                    Login
                  </button>
                } */}
              </>

}
</BaseProvider>
</StyletronProvider>

  )
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
