import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { PinCode } from "baseui/pin-code";
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider, styled } from 'baseui';
import { ThemeProvider, createTheme, lightThemePrimitives } from "baseui";
import { Notification, KIND } from 'baseui/notification';
import * as RiIcons from 'react-icons/ri';
const QRCode = require('qrcode')


const Admin = () => {
  const [allUserData, setAllUserData] = useState([]);
  const [allUserDataOG, setAllUserDataOG] = useState([]);

  const [activeIcon, setActiveIcon] = useState("lastScannedAt");
  const [activeData, setActiveData] = useState("All");

  const [locked, setLocked] = React.useState(true);
  const [passcode, setPasscode] = React.useState(["", "", "", ""])
  const [showNotiAuth, setShowNotiAuth] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUsers = allUserData.filter(user => user?.name?.toLowerCase().includes(searchTerm?.toLowerCase()));

  useEffect(() => {


    if (!passcode?.includes("")) {

      if ("6565" == passcode.join("")) {
        setLocked(false)
        window.scrollTo(0, 0)
        fetchAllUsers("all")
      }

      //Parth 
      if ("1947" == passcode.join("")) {
        setLocked(false)
        window.scrollTo(0, 0)
        fetchAllUsers("Parth")
      }





      else {
        setShowNotiAuth(true)
      }
    }


  }, [passcode]);


  const fetchAllUsers = (salesperson) => {

    console.log("Fetching data...");
    const unsubscribe = firebase.firestore().collection("users").onSnapshot(querySnapshot => { 
      const data = [];
      querySnapshot.forEach(documentSnapshot => {

        if(salesperson == "all"){

          data.push({
            id: documentSnapshot.id, 
            ...documentSnapshot.data()
          });

        }

        else{

          if(documentSnapshot?.data()?.salesperson == salesperson){

            data.push({
              id: documentSnapshot.id, 
              ...documentSnapshot.data()
            });

          }



        }


      });
      console.log("Data fetched:", data);
  
      // Sort the data based on last_scanned_at
      data.sort((a, b) => {
        const timestampA = a.last_scanned_at || 0;
        const timestampB = b.last_scanned_at || 0;
        return timestampB - timestampA;
      });
      console.log("Data sorted:", data);
  
      setAllUserData(data);
      setAllUserDataOG(data)
      console.log("Data set:", data);
    });
  
    // Unsubscribe from the snapshot listener when the component unmounts
    return () => {
      console.log("Unsubscribing...");
      unsubscribe();
    };
  };



  const sortUsersByAll = () => {  
    sortUsersByLastScannedAt()
  
    console.log("Fetching data...");
    setActiveData("All")
    const unsubscribe = firebase.firestore().collection("users").onSnapshot(querySnapshot => { 
      const data = [];
      querySnapshot.forEach(documentSnapshot => {
        data.push({
          id: documentSnapshot.id, 
          ...documentSnapshot.data()
        });
      });
      console.log("Data fetched:", data);
  
      // Sort the data based on last_scanned_at
      data.sort((a, b) => {
        const timestampA = a.last_scanned_at || 0;
        const timestampB = b.last_scanned_at || 0;
        return timestampB - timestampA;
      });
      console.log("Data sorted:", data);
  
      setAllUserData(data);
      setAllUserDataOG(data)
      console.log("Data set:", data);
    });
  
    // Unsubscribe from the snapshot listener when the component unmounts
    return () => {
      console.log("Unsubscribing...");
      unsubscribe();
    };

  }

  const sortUsersByImportant = () => {    
    console.log("Fetching data...");
    setActiveData("Important")
    sortUsersByLastScannedAt()

    const unsubscribe = firebase.firestore().collection("users").onSnapshot(querySnapshot => {
      const data = [];
      querySnapshot.forEach(documentSnapshot => {

        if(documentSnapshot?.data()?.important){

        data.push({
          id: documentSnapshot.id, 
          ...documentSnapshot.data()
        });
      }

      });
      console.log("Data fetched:", data);
  
      // Sort the data based on last_scanned_at
      data.sort((a, b) => {
        const timestampA = a.last_scanned_at || 0;
        const timestampB = b.last_scanned_at || 0;
        return timestampB - timestampA;
      });
      console.log("Data sorted:", data);
  
      setAllUserData(data);
      setAllUserDataOG(data)
      console.log("Data set:", data);
    });
  
    // Unsubscribe from the snapshot listener when the component unmounts
    return () => {
      console.log("Unsubscribing...");
      unsubscribe();
    };
  }

  const sortUsersByDemo = () => {    
    console.log("Fetching data...");
    setActiveData("Demo");
    sortUsersByLastScannedAt()

    const demoPhoneNumbers = [
        "+911111111111",
        "+912222222222",
        "+913333333333",
        "+914444444444",
        "+915555555555",
        "+916666666666",
        "+917777777777",
        "+918888888888",
        "+919999999999",
        "+911010101010",
        "+912020202020"
   
    ]; // Add more phone numbers as needed

    const unsubscribe = firebase.firestore().collection("users").onSnapshot(querySnapshot => {
        const data = [];
        querySnapshot.forEach(documentSnapshot => {
            const phoneNumber = documentSnapshot?.data()?.phonenumber;
            if(demoPhoneNumbers.includes(phoneNumber)) console.log(phoneNumber, demoPhoneNumbers.includes(phoneNumber)) 
            if (demoPhoneNumbers.includes(phoneNumber)) {
                data.push({
                    id: documentSnapshot.id, 
                    ...documentSnapshot.data()
                });
            }
        });
        
        console.log("Data fetched:", data);
  
        
  
        setAllUserData(data);
        setAllUserDataOG(data);
        console.log("Data set:", data);
    });
  
    // Unsubscribe from the snapshot listener when the component unmounts
    return () => {
        console.log("Unsubscribing...");
        unsubscribe();
    };
}

  const sortUsersByLastScannedAt = () => {
  const sortedData = [...allUserDataOG].sort((a, b) => {
    const timestampA = a.last_scanned_at || 0;
    const timestampB = b.last_scanned_at || 0;
    return timestampB - timestampA;
  });
  setAllUserData(sortedData);
  setActiveIcon('lastScannedAt');

};

  const sortUsersByAccountCreated = () => {
  const sortedData = [...allUserDataOG].sort((a, b) => {
    const timestampA = a.account_created_on || 0;
    const timestampB = b.account_created_on || 0;
    return timestampB - timestampA;
  });
  setAllUserData(sortedData);
  setActiveIcon('accountCreated');

};

  const sortUsersByPoweredBy1qrClicked = () => {
  const sortedData = [...allUserDataOG].sort((a, b) => {
    const countA = a.powered_by_1qr_clicked || 0;
    const countB = b.powered_by_1qr_clicked || 0;
    return countB - countA;
  });
  setAllUserData(sortedData);
  setActiveIcon('poweredBy1qrClicked');

};

const filterUsersByFreePlan = () => {
  const filteredData = allUserDataOG.filter(user => user.plan_type === "Free Plan");
  setAllUserData(filteredData);
  setActiveIcon('freePlan');

};

const filterUsersByImagePlan = () => {
  const filteredData = allUserDataOG.filter(user => user.plan_type === "Image Plan");
  setAllUserData(filteredData);
  setActiveIcon('imagePlan');

};

const filterUsersByVideoPlan = () => {
  const filteredData = allUserDataOG.filter(user => user.plan_type === "Video Plan");
  setAllUserData(filteredData);
  setActiveIcon('videoPlan');

};

const iconStyle = (active, icon) => ({
  width: "40px",
  height: "40px",
  padding: 6,
  backgroundColor: active === icon ? '#000' : '#FFF',
  color: active === icon ? '#FFF' : '#000',
  border: "2px solid #000",
  borderRadius: 10,
  marginRight: 10,
  cursor: 'pointer',
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center"
});


const iconStyleData = (active, icon) => ({
  width: "50px",
  height: "50px",
  padding: 6,
  backgroundColor: active === icon ? '#000' : '#FFF',
  color: active === icon ? '#FFF' : '#000',
  border: "2px solid #000",
  borderRadius: 10,
  marginRight: 10,
  cursor: 'pointer',
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center"
});
  

  function timeConverter(UNIX_timestamp){

    console.log("Enter time convertor")

    var a = UNIX_timestamp
    a = (a-(a%1000))/1000
    a = new Date(a*1000)

    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();

    var dateData = (hour + ':' + min+ ' - ' +date  + ' ' + month ).toString()
    return dateData
  }



  const updateFirestore = (uid) => {
    // Assuming you have Firebase initialized and a reference to Firestore
    const firestore = firebase.firestore();
    
    // Fetch current user data
    firestore.collection('users').doc(uid).get().then((doc) => {
        if (doc.exists) {
            const currentStatus = doc.data().important;
            
            // Update Firestore with opposite status
            firestore.collection('users').doc(uid).update({
                important: !currentStatus
            }).then(() => {
                console.log('Document successfully updated!');
            }).catch((error) => {
                console.error('Error updating document: ', error);
            });
        } else {
            console.error('No such document!');
        }
    }).catch((error) => {
        console.error('Error getting document:', error);
    });
};

const updatePhonenumber = (uid,phonenumber) => {

  const inputData = prompt(`Enter new phonenumber to link to 1QR \n old phonenumber: ${phonenumber}`);
  if (inputData !== null) {
        
        // Assuming you have Firebase initialized and a reference to Firestore
        const firestore = firebase.firestore();
        
        // Fetch current user data
        firestore.collection('users').doc(uid).get().then((doc) => {
            if (doc.exists) {
                
                // Update Firestore with opposite status
                firestore.collection('users').doc(uid).update({
                    phonenumber: inputData
                }).then(() => {
                    console.log('Document successfully updated!');
                }).catch((error) => {
                    console.error('Error updating document: ', error);
                });
            } else {
                console.error('No such document!');
            }
        }).catch((error) => {
            console.error('Error getting document:', error);
        });
        }

  
};

const getOSName = () => {
  if (navigator.userAgent.indexOf("Win") !== -1) return "Windows";
  if (navigator.userAgent.indexOf("Mac") !== -1) return "Macintosh";
  if (navigator.userAgent.indexOf("Linux") !== -1) return "Linux";
  if (navigator.userAgent.indexOf("Android") !== -1) return "Android";
  if (navigator.userAgent.indexOf("like Mac") !== -1) return "iOS";
  return "Unknown OS";
};


function newPhonenumberAlert() {

  const inputData = prompt('Enter phonenumber to link to 1QR');
  if (inputData !== null) {
      create_new_1qr(inputData);
  }

}

const openDashboard =(user)=>{
    window.open(`https://1qr.co.in/dashboard${user.uid}`, "_blank")
}


const create_new_1qr = (inputData)=>{

  console.log(" generateQRCode ==> ", inputData);
  let uid
  const OSName = getOSName();


  QRCode.toDataURL(`https://1qr.co.in/qrcode${uid}`, async (err, code) => {
    if (err) {
      return alert("BAD REQUEST. Please try again later.");
    }

    try {
      await firebase.firestore().collection("users").add({
        "qrURL": code,
        "os": OSName,
        "account_created_on": Date.now(),
        "phonenumber": inputData,
        "total_scans": 0,
        "plan_type": "Free Plan",
        "active_feature": ["call"]
      }).then((newUID)=>{
        console.log("UID generated ==> ", newUID?.id)
        uid = newUID?.id
        firebase.firestore().collection("users").doc(newUID?.id).update({uid: newUID?.id, name:inputData, phoneAuth:false })
      });

      // Initialize subcollections
      const batch = firebase.firestore().batch();
      const statsRef = firebase.firestore().collection("users").doc(uid).collection("stats").doc("visitors_data");
      const bannerImagesRef = firebase.firestore().collection("users").doc(uid).collection("banner").doc("images");
      const bannerVideosRef = firebase.firestore().collection("users").doc(uid).collection("banner").doc("videos");
      const featuresCallRef = firebase.firestore().collection("users").doc(uid).collection("features").doc("call");

      batch.set(statsRef, { "last_visit_at": [] });
      batch.set(bannerImagesRef, { "data": [] });
      batch.set(bannerVideosRef, { "data": [] });
      batch.set(featuresCallRef, { "data": { phonenumber: inputData } });

      await batch.commit();

      window.open("/dashboard" + uid, "_self");
      
    } catch (err) {
      alert("An error occurred while setting up user data. Please try again.");
      console.error("Error setting up user data:", err);
    }
  });

}



  return (
    <div style={{  padding: '20px',paddingTop:40 }}>


    {locked 
    ? <div style={{ marginTop: "1%", marginLeft: "1%" }} >
      
    <div style={{ marginTop: "30px", marginBottom: "5px", fontFamily: "UberMoveBold",fontSize:30 }}  >Enter Passcode</div>
    <h4 style={{ marginTop: "10px", marginBottom: "25px", color: "#276EF1", marginLeft: 2.5, fontSize: 20, marginRight: 10 }}  >4-digit authentication key.</h4>
    <ThemeProvider
      theme={createTheme(lightThemePrimitives, {
        colors: {
          inputBorder: "#eee",
          inputFill: "#eee",
          inputFillActive: "#fff",
          inputEnhancerFill: "#0000"
        }
      })}
    >
      <PinCode
        values={passcode}
        size={"large"}
        onChange={({ values }) => setPasscode(values)}

      /></ThemeProvider>


    {/* {
      showNotiAuth
        ?
        <div style={{ marginTop: 28 }}>
          <Notification
            kind={KIND.positive}
            closeable
            overrides={{
              Body: {
                style: ({ $theme }) => ({
                  width: "60%"
                })
              },
              InnerContainer: {
                style: ({ $theme }) => ({ color: "white" }),
                fontSize: 25
              },
              CloseIcon: {
                style: ({ $theme }) => ({ color: "white" })
              }
            }}
          >
            Incorrect Passcode
          </Notification>
        </div>
        : null

    } */}

  </div>

  
  : (
      <div>

              <div style={{ alignItems: 'center', justifyContent: "flex-start", flexDirection: "row",display:"flex", marginTop: 0, marginBottom: 0 }}>
              
              <div style={{ marginRight: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',width:"90vw" }}>

              <div style={{backgroundColor:"#EEE", display:"flex", width:"100%" ,height:"7vh" ,flexDirection:"row",margin:"3px",marginBottom:0, borderRadius:12, padding:10, justifyContent:"flex-start", alignItems:"center",marginRight:10}}>
        <RiIcons.RiSearch2Fill size={30}/>       
        <input
          type="text"
          
          placeholder="Type here .."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ height:"80%" , width:"100%",marginLeft:10, backgroundColor:"#EEE", border:"2px solid #EEE", fontSize:15}}
        />
          </div>
          <div style={{ textAlign: "center",marginLeft:-10 }}>Search by name</div>

</div>

              <div style={{ marginRight: 10, display: 'flex', width:"15%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <RiIcons.RiTeamFill 
        onClick={sortUsersByAll} 
        style={iconStyleData(activeData, 'All')}
    />
    <div style={{ textAlign: "center",marginLeft:-10 }}>All</div>
</div>

    <div style={{ display: 'flex', flexDirection: "column", width:"15%",alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' ,marginRight:10}}>
        <RiIcons.RiStarFill 
          onClick={sortUsersByImportant} 
          style={iconStyleData(activeData, 'Important')}
        />
        <div style={{ whiteSpace: 'pre-wrap',marginLeft:-10  }}>Important</div>
    </div>

    <div style={{ display: 'flex', flexDirection: "column", width:"15%",alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' ,marginRight:10}}>
        <RiIcons.RiStackFill 
          onClick={sortUsersByDemo} 
          style={iconStyleData(activeData, 'Demo')}
        />
        <div style={{ whiteSpace: 'pre-wrap',marginLeft:-10 }}>Demo</div>
    </div>

    <div style={{ display: 'flex', flexDirection: "column", width:"15%",alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' ,marginRight:10}}>
        <RiIcons.RiAddFill 
          onClick={newPhonenumberAlert} 
          style={iconStyleData(activeData, 'Demo')}
        />
        <div style={{ whiteSpace: 'pre-wrap',marginLeft:-10 }}>Create 1QR </div>
    </div>

</div>

              <div style={{ alignItems: 'center', justifyContent: "space-between", flexDirection: "row",display:"flex",flexWrap:"wrap", marginTop: 20, marginBottom: 30 }}>
    
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection:"column", fontFamily: 'UberMoveRegular', textAlign: 'center' }}>
        <RiIcons.RiQrScan2Line 
          onClick={sortUsersByLastScannedAt} 
          style={iconStyle(activeIcon, 'lastScannedAt')}
        />
        <div>Scanned</div>
    </div>

    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' }}>
        <RiIcons.RiGobletFill 
          onClick={sortUsersByAccountCreated} 
          style={iconStyle(activeIcon, 'accountCreated')}
        />
        <div style={{ whiteSpace: 'pre-wrap' }}>Created</div>
    </div>

    <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' }}>
        <RiIcons.RiFootprintFill 
          onClick={sortUsersByPoweredBy1qrClicked} 
          style={iconStyle(activeIcon, 'poweredBy1qrClicked')}
        />
        <div>1QR.co.in</div>
    </div>

    <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' }}>
        <RiIcons.RiGift2Fill 
          onClick={filterUsersByFreePlan} 
          style={iconStyle(activeIcon, 'freePlan')}
        />
        <div>Free Plan</div>
    </div>

    <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' }}>
        <RiIcons.RiImage2Fill 
          onClick={filterUsersByImagePlan} 
          style={iconStyle(activeIcon, 'imagePlan')}
        />
        <div>Image Plan</div>
    </div>

    <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center', justifyContent: 'center', fontFamily: 'UberMoveRegular', textAlign: 'center' }}>
        <RiIcons.RiFilmFill 
          onClick={filterUsersByVideoPlan} 
          style={iconStyle(activeIcon, 'videoPlan')}
        />
        <div>Video Plan</div>
    </div>
</div>

        {/* Search bar */}

        


        {/* User data */}
        {filteredUsers.map(user => (
          <div
            style={{ backgroundColor: '#EEE', borderRadius: 12, padding: '10px', marginBottom: '10px' }}
            key={user?.id}
          >


<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
  <div style={{ fontFamily: "UberMoveBold", fontSize: 16 }}>{user?.name}</div>
  <div style={{ fontFamily: "UberMoveBold", fontSize: 16 }}>
    {activeIcon === 'poweredBy1qrClicked' 
      ? user?.powered_by_1qr_clicked || 0
      : activeIcon === 'accountCreated' 
        ? timeConverter(user?.account_created_on)
        : user?.total_scans
    }
  </div>
</div>

<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
  <div style={{ fontFamily: "UberMoveMedium", fontSize: 12, color: "#222" }}>
    { timeConverter(user?.last_scanned_at)}
  </div>
  <div style={{ fontFamily: "UberMoveMedium", fontSize: 12, color: "#222" }}>
    {activeIcon === 'poweredBy1qrClicked' 
      ? '1QR.co.in Clicks' 
      : activeIcon === 'accountCreated' 
        ? 'Account Created' 
        : 'Scans'}
  </div>
</div>



            {/* Buttons for stats and QR code page */}
            <div style={{ marginTop: '15px' }}>
              <button onClick={() => window.open(`https://1qr.co.in/stats${user?.id}`, "_blank")} style={{ marginRight: '10px', borderRadius: 10, border: "2px solid #EEE", backgroundColor: "#EEE", padding: 5, color:"#000" }}><RiIcons.RiCupFill size={22}/></button>
              <button onClick={() => window.open(`https://1qr.co.in/test${user?.id}`, "_blank")} style={{ marginRight: '10px', borderRadius: 10, border: "2px solid #EEE", backgroundColor: "#EEE", padding: 5, color:"#000" }}><RiIcons.RiQrCodeFill size={22}/></button>
              <button style={{ marginRight: '10px', borderRadius: 10, border: "2px solid #EEE", backgroundColor: "#EEE", padding: 5, color:"#000" }}>{user?.plan_type == "Video Plan"?<RiIcons.RiFilmFill size={22}/>:user?.plan_type == "Image Plan"?<RiIcons.RiImage2Fill size={22}/>:<RiIcons.RiGift2Fill size={22}/>}</button>
              <button onClick={() => window.open(`tel:${user?.phonenumber}`, "_self")} style={{ marginRight: '10px', borderRadius: 10, border: "2px solid #EEE", backgroundColor: "#EEE", padding: 5, color:"#000" }}><RiIcons.RiUser2Fill size={22}/></button>
              <button onClick={() => openDashboard(user) } style={{ marginRight: '10px', borderRadius: 10, border: "2px solid #EEE", backgroundColor: "#EEE", padding: 5, color:"#000" }}><RiIcons.RiDashboardFill size={22}/></button>
              <button onClick={() => updateFirestore(user?.id)} style={{ marginRight: '10px', borderRadius: 10, border: "2px solid #EEE", backgroundColor: "#EEE", padding: 5, color:"#000" }}>
                      {user?.important ? <RiIcons.RiStarFill size={22} /> : <RiIcons.RiStarLine size={22} />}
                  </button>
                  {user?.phoneAuth == false
                  ?<button onClick={() => updatePhonenumber(user?.id, user?.phonenumber)} style={{ marginRight: '10px', borderRadius: 10, border: "2px solid #EEE", backgroundColor: "#EEE", padding: 5, color:"#000" }}>
                      <RiIcons.RiRobotFill size={22} />
                  </button>
                  :null}
</div>
          </div>
        ))}
      </div>
    )}
  </div>
  );
};

export default Admin;
